import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { isSameDay } from "date-fns";
import { reducer } from "./reducer";
import { useAuthUser } from "../Auth";
import api from "../../services/api";
import { socket } from "../../services/socket-io";
import { toast } from "react-toastify";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";

const TicketsContext = React.createContext();

export function TicketsProvider(props) {
  const history = useHistory();
  const [ticket, setTicket] = React.useState(null);
  const [isLoading, setLoading] = React.useState(true);
  const [mounted, setMounted] = React.useState(false);
  const [tickets, dispatch] = React.useReducer(reducer, {
    open: { count: 0, hasMore: false, tickets: [] },
    pending: { count: 0, hasMore: false, tickets: [] },
    closed: { count: 0, hasMore: false, tickets: [] },
  });

  let { ticketId } = useParams();

  const { user } = useAuthUser();

  React.useEffect(() => {
    async function initialFetchTickets() {
      if (!user || mounted) return;

      const queueIds =
        user.profile !== "owner" && user.profile !== "admin"
          ? user?.queues.map((elem) => elem.id)
          : [];

      const { data } = await api.get("/tickets/user", {
        params: {
          queueIds: queueIds.join(";"),
        },
      });

      setMounted(true);
      dispatch({
        type: "FETCH_TICKETS",
        payload: data,
      });
    }

    initialFetchTickets().finally(() => {
      setLoading(false);
    });
  }, [user, mounted]);

  React.useEffect(() => {
    if (!user || !user.companyId) return;
    const handleSocketEvent = (event) => {
      const att = event.where;
      const action = event.action;
      setLoading(true);

      if (action === "read") {
        dispatch({
          type: "TICKET_READ",
          payload: event.data,
          att,
        });
        setLoading(false);
        return;
      }

      if (att === "open" && event.data.id === ticketId) {
        dispatch({
          type: "TICKET_READ",
          payload: event.data.id,
          att,
        });
      }

      const isAdmin = user.profile === "owner" || user.profile === "admin";

      const belongQueue = user.queues.some((e) => e.id === event.data.queueId);

      const isSupervisor = user.profile === "supervisor" && belongQueue;

      const isMember =
        (att === "pending" && belongQueue) ||
        (user.id === event.data.userId && att === "open");

      if (isAdmin || isSupervisor || isMember) {
        dispatch({
          type: "UPDATE_TICKET",
          payload: event.data,
          att,
        });
      }
      setLoading(false);
    };

    const handleSocketDelete = (event) => {
      setLoading(true);
      dispatch({
        type: "DELETE_TICKET",
        payload: event.data,
        att: event.where,
      });
      setLoading(false);
    };

    const handleSocketTransfer = (event) => {
      const {
        data: { oldUserId, oldQueueId },
        where: att,
      } = event;
      setLoading(true);

      const isAdmin = user.profile === "owner" || user.profile === "admin";

      const belongQueue = user.queues.some(
        (e) => e.id === oldQueueId && oldQueueId !== event.data.ticket.queueId,
      );

      const isSupervisor =
        (user.profile === "supervisor" && belongQueue) ||
        user.id !== event.data.ticket.userId;

      const isMember = user.id === oldUserId && belongQueue && att === "open";

      if (isAdmin) return setLoading(false);

      if (isSupervisor || isMember) {
        dispatch({
          type: "DELETE_TICKET",
          payload: event.data.ticket.id,
          att,
        });
      }
      setLoading(false);
    };

    socket.on(`ticket:${user.companyId}:update`, handleSocketEvent);

    socket.on(`ticket:${user.companyId}:delete`, handleSocketDelete);
    socket.on(`ticket:${user.companyId}:transfer`, handleSocketTransfer);

    return () => {
      socket.off(`ticket:${user.companyId}:update`, handleSocketEvent);
      socket.off(`ticket:${user.companyId}:delete`, handleSocketDelete);
      socket.off(`ticket:${user.companyId}:transfer`, handleSocketTransfer);
    };
  }, [user, ticketId, mounted]);

  const fetchTickets = React.useCallback(async (queryIds = []) => {
    const params = {
      queueIds: JSON.stringify(queryIds),
    };

    const { data } = await api.get("/tickets", {
      params,
    });

    dispatch({
      type: "FETCH_TICKETS",
      payload: data,
    });

    return;
  }, []);

  function getTicketsByStatus(status) {
    return tickets[status].tickets;
  }

  function getTicketsToday() {
    const items = Object.keys(tickets).map((key) => tickets[key].tickets);
    return items
      .flat()
      .filter((e) => isSameDay(new Date(e.createdAt), new Date()));
  }

  function getAllTickets() {
    const items = Object.keys(tickets).map((key) => tickets[key].tickets);
    return items.flat();
  }

  function getTicketById(id) {
    const items = Object.keys(tickets)
      .map((key) => tickets[key]?.tickets || [])
      .flat();

    const item = items.find((e) => e.id === id);

    if (item) {
      setTicket(item);
      history.push(`/tickets/${item.id}`);
    }
  }

  const handleUpdateTicketKanban = async () => {
    try {
      if (!ticket);
      if (ticket.isKanban) {
        await api.put("/kanban/ticket", {
          tagId: null,
          isKanban: false,
          ticketId: ticket.id,
        });

        ticket.isKanban = false;
        ticket.kanbanTagId = null;
        ticket.kanbanTag = null;

        setTicket({ ...ticket });

        toast.success(i18n.t("ticketOptionsMenu.kanban.remove_success"));
      } else {
        await api.put("/kanban/ticket", {
          isKanban: true,
          ticketId: ticket.id,
        });

        ticket.isKanban = true;
        ticket.kanbanTagId = null;
        ticket.kanbanTag = null;
        setTicket({ ...ticket });

        toast.success(i18n.t("ticketOptionsMenu.kanban.add_success"));
      }
    } catch (err) {
      toastError(err);
    }
  };

  return (
    <TicketsContext.Provider
      value={{
        tickets,
        isLoading,
        fetchTickets,
        getTicketsByStatus,
        getTicketsToday,
        getTicketById,
        getAllTickets,
        setTicket,
        ticket,
        handleUpdateTicketKanban,
      }}
    >
      {props.children}
    </TicketsContext.Provider>
  );
}

export const useTickets = () => {
  return React.useContext(TicketsContext);
};

export default TicketsContext;
